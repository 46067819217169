<template>
  <div class="app-container">
    <div ref="contact" class="head-container">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增审批规则</el-button>
    </div>  
    <el-table v-loading="loading" row-key="id" :data="data">
      <el-table-column min-width="120">
        <template slot-scope="scope">
          <span>单价低于统一零售价{{(scope.row.discount/100).toFixed(2)+'%'}} 则需要角色{{(scope.row.roleName)}}审批</span>
        </template>
      </el-table-column>
      <el-table-column width="125" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <Form ref="form" :is-add="isAdd" /> 
  </div>
</template>
<script>
import initData from "@/mixins/initData";
import { del, edit } from "@/api/priceCheckSet";
import { getAll, getLevel } from "@/api/role";
import Form from "./form";
import checkPermission from "@/utils/permission";
export default {
  components: { Form },
  mixins: [initData],
  data() {
    return {
      isAdd: false,
      delLoading: false
    };
  },
  created() {
      this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/priceCheckSet";
      this.params = { sort: "id,desc" };
      return true;
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.restForm(JSON.parse(JSON.stringify(data)));
    },

    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  }
};
</script>